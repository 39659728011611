import axios from "axios";
import { gsap } from "gsap";
const api = axios.create({
  timeout: 30000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default () => {
  return {
    settings: {},
    page(context) {
      // check if path is current path
      if (context.path === window.initialPath) {
        console.log("Initial path is current path")
        window.initialPath = null;
        return;
      } else {
        console.log("Initial path is not current path")
        console.log('context.path: ' + context.path)
        console.log('window.initialPath: ' + window.initialPath)
      }
      console.log(context);
      const tl = gsap.timeline({ defaults: { duration: 0.5 } });
      api.get(context.path).then((response) => {
        // parse response data as html
        const parser = new DOMParser();
        const html = parser.parseFromString(response.data, "text/html");
        // get new page title
        const newTitle = html.querySelector("#ajax-meta .title").innerText;
        document.title = newTitle;

        // register pageView with google analytics
        if (typeof gtag !== "undefined") {
          // Register pageview
          gtag("event", "page_view", {
            page_path: window.location.pathname + window.location.search,
            page_title: newTitle,
          });
        }

        tl.to("#app", {
          opacity: 0,
          onComplete: () => {
            document.querySelector("#app").innerHTML = response.data;
          },
        });
        tl.to("#app", { opacity: 1 });
      });
    },
    notfound(context) {
      document.querySelector("#app").innerHTML = `<h1>Not Found</h1>`;
    },
  };
};
